import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Header from "../components/layout/js/header"
import Overlay from "../components/layout/js/overlay"
import Galerie from "../components/galerie/js/galerieLoadable"
import Contacter from "../components/layout/js/contacter"
import Footer from "../components/layout/js/footer"
import Copyrights from "../components/layout/js/copyrights"

const GaleriePage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query {
      projets: allFile(
        filter: { relativeDirectory: { eq: "galerie/Projets d'envergures" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                src
                srcSet
                sizes
                originalName
                presentationWidth
                presentationHeight
                base64
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Galerie projets d'envergures" />
      <Header />
      <Overlay />
      <Galerie
        query={data}
        titre={intl.formatMessage({ id: "galeries.projet.title" })}
        soustitre={intl.formatMessage({ id: "galeries.projet.subtitle" })}
      />
      <Contacter />
      <Footer />
      <Copyrights />
    </>
  )
}

export default GaleriePage
